import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'
import getGasPrice from 'utils/getGasPrice'
import { AddressZero } from '@ethersproject/constants'
import {callWithEstimateGas} from './estimateGas'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeYard = async (vaultContract, amount, userRefferer) => {
  const gasPrice = getGasPrice()

  let referrer = userRefferer
  if (!userRefferer || !userRefferer.startsWith('0x')) {
    referrer = AddressZero
  }

  const tx = await callWithEstimateGas(vaultContract, 'deposit', [amount, referrer], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const stakeLPYard = async (vaultContract, lpAmount, userRefferer) => {
  const gasPrice = getGasPrice()

  let referrer = userRefferer
  if (!userRefferer || !userRefferer.startsWith('0x')) {
    referrer = AddressZero
  }

  const tx = await callWithEstimateGas(vaultContract, 'depositLP', [lpAmount, referrer], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const stakeBNBYard = async (vaultContract, amount, userRefferer) => {
  const gasPrice = getGasPrice()

  let referrer = userRefferer
  if (!userRefferer || !userRefferer.startsWith('0x')) {
    referrer = AddressZero
  }

  const tx = await callWithEstimateGas(vaultContract, 'deposit', [referrer], {
    gasPrice,
  },1000, amount.toString())
  const receipt = await tx.wait()
  return receipt.status
}

export const stakeLPBNBYard = async (vaultContract, lpAmount, userRefferer) => {
  const gasPrice = getGasPrice()

  let referrer = userRefferer
  if (!userRefferer || !userRefferer.startsWith('0x')) {
    referrer = AddressZero
  }

  const tx = await callWithEstimateGas(vaultContract, 'depositLP', [lpAmount, referrer], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeYard = async (vaultContract, amount) => {
  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(vaultContract, 'withdraw', [amount], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeLPYard = async (vaultContract, lpAmount) => {
  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(vaultContract, 'withdrawLP', [lpAmount], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const migrateYard = async (vaultContract) => {
  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(vaultContract, 'migrateFrom', [], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestYard = async (vaultContract) => {
  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(vaultContract, 'harvest', [], {
    gasPrice,
  })
  const receipt = await tx.wait()
  /* eslint-disable dot-notation */
  if (receipt.status === 1) {
    const ev = Array.from(receipt["events"]).filter((v) =>  {
        return v["event"] === "RewardPaid"
    });

    if (ev.length > 0) {
        const args = ev[0]["args"];
        return new BigNumber(args["reward"]._hex)
    }
  }
  /* eslint-enable dot-notation */
  return null
}
