import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import yardsConfig, {oldYards} from 'config/constants/yards'
import {
  fetchYardUserDatas,
} from './fetchYardUser'
import { SerializedVaultsState, SerializedVault } from '../types'
import fetchYards from './fetchYards'

const noAccountYardsConfig = yardsConfig.map((yard) => ({
  ...yard,
  isOld: false,
  userData: {
    tokenAllowance: '0',
    lpAllowance: '0',
    lpTokenBalance: '0',
    tokenBalanceInVault: '0',
    stakedBalance: '0',
    pendingEarnings: '0',
    earnings: '0',
  },
}))

const noAccountFarmConfigOld = oldYards.map((yard) => ({
  ...yard,
  isOld: true,
  userData: {
    tokenAllowance: '0',
    lpAllowance: '0',
    lpTokenBalance: '0',
    tokenBalanceInVault: '0',
    stakedBalance: '0',
    pendingEarnings: '0',
    earnings: '0',
  },
}))

const initialState: SerializedVaultsState = {
  data: noAccountYardsConfig,
  old: noAccountFarmConfigOld,
  loadArchivedVaultsData: false,
  userDataLoaded: false,
  oldUserDataLoaded: false,
}

// Async thunks
export const fetchYardsPublicDataAsync = createAsyncThunk<SerializedVault[], number[]>(
  'yards/fetchYardsPublicDataAsync',
  async (pids) => {
    const yardsToFetch = yardsConfig.filter((yardConfig) => pids.includes(yardConfig.pid))

    const yards = await fetchYards(yardsToFetch)

    return yards
  },
)

// Async thunks
export const fetchOldYardsPublicDataAsync = createAsyncThunk<SerializedVault[], number[]>(
  'yards/fetchOldYardsPublicDataAsync',
  async (pids) => {
    const yardsToFetch = oldYards.filter((yardConfig) => pids.includes(yardConfig.pid))
    const yards = await fetchYards(yardsToFetch, true)

    return yards
  },
)

interface YardUserDataResponse {
  pid: number
  tokenAllowance: string
  lpAllowance: string
  lpTokenBalance: string
  tokenBalanceInVault: string
  stakedBalance: string
  pendingEarnings: string
  earnings: string
}

export const fetchYardUserDataAsync = createAsyncThunk<YardUserDataResponse[], { account: string; pids: number[] }>(
  'yards/fetchYardUserDataAsync',
  async ({ account, pids }) => {
    const yardsToFetch = yardsConfig.filter((yardConfig) => pids.includes(yardConfig.pid))
    const datas = await fetchYardUserDatas(account, yardsToFetch)
    return datas.map((data, index) => {
      return {
        ...data,
        pid: yardsToFetch[index].pid
      }
    })
  },
)

export const fetchOldYardUserDataAsync = createAsyncThunk<YardUserDataResponse[], { account: string; pids: number[] }>(
  'yards/fetchOldYardUserDataAsync',
  async ({ account, pids }) => {
    const yardsToFetch = oldYards.filter((yardConfig) => pids.includes(yardConfig.pid))
    const datas = await fetchYardUserDatas(account, yardsToFetch)
    return datas.map((data, index) => {
      return {
        ...data,
        pid: yardsToFetch[index].pid
      }
    })
  },
)

export const yardsSlice = createSlice({
  name: 'Yards',
  initialState,
  reducers: {
    setLoadArchivedVaultsData: (state, action) => {
      const loadArchivedVaultsData = action.payload
      state.loadArchivedVaultsData = loadArchivedVaultsData
    },
  },
  extraReducers: (builder) => {
    // Update yards with live data
    builder.addCase(fetchYardsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((yard) => {
        const liveYardData = action.payload.find((yardData) => yardData.pid === yard.pid)
        return { ...yard, ...liveYardData }
      })
    })

    // Update yards with user data
    builder.addCase(fetchYardUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex((yard) => yard.pid === pid)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
    // Update yards with live data
    builder.addCase(fetchOldYardsPublicDataAsync.fulfilled, (state, action) => {
      state.old = state.old.map((yard) => {
        const liveYardData = action.payload.find((yardData) => yardData.pid === yard.pid)
        return { ...yard, ...liveYardData }
      })
    })

    // Update yards with user data
    builder.addCase(fetchOldYardUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.old.findIndex((yard) => yard.pid === pid)
        state.old[index] = { ...state.old[index], userData: userDataEl }
      })
      state.oldUserDataLoaded = true
    })
  },
})

// Actions
export const { setLoadArchivedVaultsData } = yardsSlice.actions

export default yardsSlice.reducer