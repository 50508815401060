import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'
import tokens from 'config/constants/tokens'

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeRabbitsAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getLotteryV2Address,
  getMasterChefAddress,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getSpynVaultAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getBunnySpecialSpynVaultAddress,
  getBunnySpecialPredictionAddress,
  getBunnySpecialLotteryAddress,
  getFarmAuctionAddress,
  getAnniversaryAchievement,
  getNftMarketAddress,
  getNftSaleAddress,
  getPancakeSquadAddress,
  getNFTMintroxyAddress,
  getNFTMarketplaceAddress,
  getGovernanceAddress,
  getAdminAddress,
  getOldMasterChefAddress,
  getSpyNFTRewardAddress,
  getSpynNFTRewardAddress,
  getSpyNFTFactoryAddress,
  getSpynNFTFactoryAddress,
  getAdminOldAddress,
  getLpConverterAddress,
  getNFTSignatureFactoryAddress,
  getNFTSignatureMintProxyAddress,
  getNFTSignatureRewardAddress,
  getNFTSignatureInsuranceAddress,
  getSpynNFT2RewardAddress,
  getSpynNFT2FactoryAddress,
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryV2Abi from 'config/abi/lotteryV2.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json'
import easterNftAbi from 'config/abi/easterNft.json'
import spynVaultAbi from 'config/abi/spynVault.json'
import predictionsAbi from 'config/abi/predictions.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialSpynVaultAbi from 'config/abi/bunnySpecialSpynVault.json'
import bunnySpecialPredictionAbi from 'config/abi/bunnySpecialPrediction.json'
import bunnySpecialLotteryAbi from 'config/abi/bunnySpecialLottery.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import anniversaryAchievementAbi from 'config/abi/anniversaryAchievement.json'
import nftMarketAbi from 'config/abi/nftMarket.json'
import nftSaleAbi from 'config/abi/nftSale.json'
import pancakeSquadAbi from 'config/abi/pancakeSquad.json'
import erc721CollctionAbi from 'config/abi/erc721collection.json'
import generalNFTRewardAbi from 'config/abi/generalNFTReward.json'
import spyNFT from 'config/abi/spyNFT.json'
import spyNFTFactory from 'config/abi/spyNFTFactory.json'
import spyNFTMintProxy from 'config/abi/spyNFTMintProxy.json'
import spyNFTMarketplace from 'config/abi/spyNFTMarketplace.json'
import spynNFTFactoryAbi from 'config/abi/spynNFTFactory.json'
import bnbSpyVaultAbi from 'config/abi/bnbSpyVault.json'
import compoundVaultAbi from 'config/abi/compoundVault.json'
import spynGovernorAbi from 'config/abi/spynGovernor.json'
import spynAdminAbi from 'config/abi/spynAdmin.json'
import spynAbi from 'config/abi/spyn.json'
import lpConverterAbi from 'config/abi/lpConverter.json'
import bnbYardAbi from 'config/abi/bnbYard.json'
import compoundYardAbi from 'config/abi/compoundYard.json'
import nftSignatureFactorybi from 'config/abi/nftSignatureFactory.json'
import nftSignatureMintProxybi from 'config/abi/nftSignatureMintProxy.json'
import nftSignatureRewardAbi from 'config/abi/nftSignatureReward.json'
import nftSignatureInsuranceAbi from 'config/abi/nftSignatureInsurance.json'
import { ChainLinkOracleContract, FarmAuctionContract, PancakeProfileContract, PredictionsContract } from './types'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getSouschefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer)
}
export const getSouschefV2Contract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer)
}
export const getPointCenterIfoContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), signer)
}
export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, tokens.cake.address, signer)
}

export const getProfileContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(profileABI, getPancakeProfileAddress(), signer) as PancakeProfileContract
}
export const getPancakeRabbitContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), signer)
}
export const getBunnyFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), signer)
}
export const getBunnySpecialContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer)
}
export const getLotteryV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryV2Abi, getLotteryV2Address(), signer)
}
export const getLPConverterContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpConverterAbi, getLpConverterAddress(), signer)
}
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getOldMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getOldMasterChefAddress(), signer)
}

export const getNFTMarketplaceContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spyNFTMarketplace, getNFTMarketplaceAddress(), signer)
}
export const getSpyNFTRewardContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(generalNFTRewardAbi, getSpyNFTRewardAddress(), signer)
}
export const getSpynNFTRewardContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(generalNFTRewardAbi, getSpynNFTRewardAddress(), signer)
}
export const getSpynNFT2RewardContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(generalNFTRewardAbi, getSpynNFT2RewardAddress(), signer)
}
export const getSpyNFTContract = (tokenAddress: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spyNFT, tokenAddress, signer)
}
export const getNFTFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spynNFTFactoryAbi, getSpynNFTFactoryAddress(), signer)
}
export const getNFT2FactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spynNFTFactoryAbi, getSpynNFT2FactoryAddress(), signer)
}
export const getNFTMintProxyContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spyNFTMintProxy, getNFTMintroxyAddress(), signer)
}
export const getSpyNFTFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spyNFTFactory, getSpyNFTFactoryAddress(), signer)
}
export const getGovernanceContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spynGovernorAbi, getGovernanceAddress(), signer)
}
export const getAdminContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spynAdminAbi, getAdminAddress(), signer)
}
export const getAdminOldContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spynAdminAbi, getAdminOldAddress(), signer)
}
export const getClaimRefundContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer)
}
export const getTradingCompetitionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), signer)
}
export const getEasterNftContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(easterNftAbi, getEasterNftAddress(), signer)
}
export const getSpynVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spynVaultAbi, getSpynVaultAddress(), signer)
}

export const getPredictionsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(predictionsAbi, getPredictionsAddress(), signer) as PredictionsContract
}

export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer) as ChainLinkOracleContract
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getBunnySpecialSpynVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialSpynVaultAbi, getBunnySpecialSpynVaultAddress(), signer)
}
export const getBunnySpecialPredictionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialPredictionAbi, getBunnySpecialPredictionAddress(), signer)
}
export const getBunnySpecialLotteryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialLotteryAbi, getBunnySpecialLotteryAddress(), signer)
}
export const getFarmAuctionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(farmAuctionAbi, getFarmAuctionAddress(), signer) as FarmAuctionContract
}
export const getAnniversaryAchievementContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(anniversaryAchievementAbi, getAnniversaryAchievement(), signer)
}
export const getNftMarketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftMarketAbi, getNftMarketAddress(), signer)
}
export const getNftSaleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftSaleAbi, getNftSaleAddress(), signer)
}
export const getPancakeSquadContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pancakeSquadAbi, getPancakeSquadAddress(), signer)
}
export const getErc721CollectionContract = (signer?: ethers.Signer | ethers.providers.Provider, address?: string) => {
  return getContract(erc721CollctionAbi, address, signer)
}

export const getBNBVaultContract = (contractAddress: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bnbSpyVaultAbi, contractAddress, signer)
}

export const getCompoundVaultContract = (contractAddress: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(compoundVaultAbi, contractAddress, signer)
}
export const getSpynContract = (contractAddress: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(spynAbi, contractAddress, signer)
}

export const getBNBYardContract = (contractAddress: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bnbYardAbi, contractAddress, signer)
}

export const getCompoundYardContract = (contractAddress: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(compoundYardAbi, contractAddress, signer)
}

export const getNFTSignatureFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftSignatureFactorybi, getNFTSignatureFactoryAddress(), signer)
}
export const getNFTSignatureMinProxyContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftSignatureMintProxybi, getNFTSignatureMintProxyAddress(), signer)
}
export const getNFTSignatureRewardContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftSignatureRewardAbi, getNFTSignatureRewardAddress(), signer)
}
export const getNFTSignatureInsuranceContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftSignatureInsuranceAbi, getNFTSignatureInsuranceAddress(), signer)
}