import { getReferralAddress } from "utils/addressHelpers";
import multicall from "utils/multicall";
import referralAbi from "config/abi/referral.json"
import BigNumber from "bignumber.js";

export interface ReferralPublicData {
  referrerAvailable: string
  referrerDepth: number
  randomReferrer: string
  currentReferrer: string
}

export const fetchPublicReferralData = async(account: string): Promise<ReferralPublicData> =>  {

    const referralAddress = getReferralAddress()

    const calls = [
        {
          address: referralAddress,
          name: 'nextReferrerAvailable',
          params: [account],
        },
        {
            address: referralAddress,
            name: 'randomReferrer',
            params: [account],
        },
        {
            address: referralAddress,
            name: 'referrers',
            params: [account],
        }
    ];

    const [[referrerAvailable, depth], [randomReferrer], [currentReferrer]] =
    await multicall(referralAbi, calls)

    return {
        referrerAvailable,
        referrerDepth: new BigNumber(depth._hex).toNumber(),
        randomReferrer,
        currentReferrer
    };
}