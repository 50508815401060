import BigNumber from 'bignumber.js'
import tokens from 'config/constants/tokens'
import { getSpyNFTContract, getSpyNFTRewardContract, getSpynNFT2RewardContract, getSpynNFTRewardContract } from 'utils/contractHelpers';

export const fetchNFTBalance = async (nftAddress: string, account: string): Promise<SerializedBigNumber[]> => {

    const nftContract = getSpyNFTContract(nftAddress);

    const tokenIds = await nftContract.tokensOfOwner(account)

    return tokenIds.map((tokenId) => new BigNumber(tokenId._hex).toJSON())
}

export const fetchStakedSpyNFTs = async (account: string): Promise<SerializedBigNumber[]> => {
    const contract = getSpyNFTRewardContract();
    const tokenIds = await contract.getPlayerIds(account)
    return tokenIds.map((tokenId) => new BigNumber(tokenId._hex).toJSON())
}

export const fetchStakedSpynNFTs = async (account: string): Promise<SerializedBigNumber[]> => {
    const contract = getSpynNFTRewardContract();
    const tokenIds = await contract.getPlayerIds(account)
    return tokenIds.map((tokenId) => new BigNumber(tokenId._hex).toJSON())
}

export const fetchStakedSpynNFT2s = async (account: string): Promise<SerializedBigNumber[]> => {
    const contract = getSpynNFT2RewardContract();
    const tokenIds = await contract.getPlayerIds(account)
    return tokenIds.map((tokenId) => new BigNumber(tokenId._hex).toJSON())
}