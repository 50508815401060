import poolsConfig from 'config/constants/pools'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getMasterchefContract } from 'utils/contractHelpers'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'

// Pool 0, Spy / Spy is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'BNB')
const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'BNB')
const masterChefContract = getMasterchefContract()

export const fetchPoolsAllowance = async (account) => {
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress)],
  }))
 
  const allowances = await multicall(erc20ABI, calls)
  return nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account) => {
  // Non BNB pools
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(erc20ABI, calls)
  const tokenBalances = nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )

  return { ...tokenBalances, }
}

export const fetchPoolUserInfo = async(account) => {


  const masterChefAddr = getMasterChefAddress()
  const [info, extraHarvestInterval, extraLockInterval, pendingSpyn] = await multicall(masterchefABI, [
    {
      address: masterChefAddr,
      name: 'userInfo',
      params: [0, account],
    },
    {
      address: masterChefAddr,
      name: 'extraHarvestIntervalFor',
      params: [0, account],
    }, 
    {
      address: masterChefAddr,
      name: 'extraLockIntervalFor',
      params: [0, account],
    }, 
    {
      address: masterChefAddr,
      name: 'pendingSpyn',
      params: [0, account],
    }, 
  ])

  const parsedStakedBalance = new BigNumber(info[0]._hex).toJSON()
  const parsedNextHarvestUntil = new BigNumber(info[3]._hex).toJSON()
  const parsedLockedAmount = new BigNumber(info[2]._hex).toJSON()
  const parsedExtraHarvestInterval = new BigNumber(extraHarvestInterval[0]._hex).toJSON()
  const parsedExtraLockInterval = new BigNumber(extraLockInterval[0]._hex).toJSON()
  const parsedPendingSpyn = new BigNumber(pendingSpyn[0]._hex).toJSON()

  return {
    0: {
      stakedBalance: parsedStakedBalance,
      nextHarvestUntil: parsedNextHarvestUntil,
      extraHarvestInterval: parsedExtraHarvestInterval,
      extraLockInterval: parsedExtraLockInterval,
      lockedAmount: parsedLockedAmount,
      pendingReward: parsedPendingSpyn,
    }
  }
}

export const fetchUserStakeBalances = async (account) => {

  // Spy / Spy pool
  const { amount: masterPoolAmount } = await masterChefContract.userInfo('0', account)

  return { 0: new BigNumber(masterPoolAmount.toString()).toJSON() }
}

export const fetchUserPendingRewards = async (account) => {
  // Spy / Spy pool
  const pendingReward = await masterChefContract.pendingSpyn('0', account)

  return { 0: new BigNumber(pendingReward.toString()).toJSON() }
}