import { serializeTokens } from './tokens'
import { SerializedVaultConfig } from './types'

const serializedTokens = serializeTokens()


export const oldYards: SerializedVaultConfig[] = [
    {
        pid: 1,
        lpSymbol: 'SPYN-BUSD',
        symbol: 'BUSD',
        lpAddresses: {
            97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
            56: '0x0eabECCcBeF41be0BF3e8453e858B45b3096eDf7',
        },
        contractAddresses: {
            97: '0xaA3fbB01193b88f78fb868dA152B15eEe8C6b572',
            56: '0x075daa6a86fECB220F9fA07929a7C3850281b759',
        },
        isETH: false,
        token: serializedTokens.busd,
    },
    {
        pid: 2,
        symbol: 'BNB',
        lpSymbol: 'SPYN-BNB',
        lpAddresses: {
            97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
            56: '0xC0279456619E6F9C3fba4be59458dDC0e02166b2',
        },
        contractAddresses: {
            97: '0x752b76C7f05a39a0506e92C793201d62f40A9A3B',
            56: '0x64d43A18ef8Ff19E38C1E3A3E30400d7782447B3',
        },
        isETH: true,
        token: serializedTokens.wbnb,
    },
    {
        pid: 4,
        symbol: 'USDC',
        lpSymbol: 'SPYN-USDC',
        lpAddresses: {
            97: '0x296ae37b2366e76A228f439826e5B413465162b4',
            56: '0x026B24780Ad17515f0b2ad500E26F1Da82E5e544',
        },
        contractAddresses: {
            97: '0x7cA263bb1B70146B2994D69EFB321668f297C963',
            56: '0xb8faCF6619598dBdb671e3D178a5012a058d135c',
        },
        isETH: false,
        token: serializedTokens.usdc,
    },
    {
        pid: 5,
        symbol: 'SPY',
        lpSymbol: 'SPYN-SPY',
        lpAddresses: {
            97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
            56: '0x8cce7BE8cBeB16150Efea91CbfDe157bbb7994f5',
        },
        contractAddresses: {
            97: '0x3e4cC94B035D82122fC3065CbefEaeac3B99797F',
            56: '0x932Eae574dD24be50Ee85e9b20ffBAa7A187A492',
        },
        isETH: false,
        token: serializedTokens.spy,
    }
]


const yards: SerializedVaultConfig[] = [
    {
        pid: 1,
        lpSymbol: 'SPYN-BUSD',
        symbol: 'BUSD',
        lpAddresses: {
            97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
            56: '0x0eabECCcBeF41be0BF3e8453e858B45b3096eDf7',
        },
        contractAddresses: {
            97: '0x38009935A350478676C6956Ba381Ef61667890D4',
            56: '0x075daa6a86fECB220F9fA07929a7C3850281b759',
        },
        isETH: false,
        token: serializedTokens.busd,
    },
    {
        pid: 2,
        symbol: 'BNB',
        lpSymbol: 'SPYN-BNB',
        lpAddresses: {
            97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
            56: '0xC0279456619E6F9C3fba4be59458dDC0e02166b2',
        },
        contractAddresses: {
            97: '0xCD5aD07E8CDa753ab5DcF2dD16687ef91842D5Cc',
            56: '0x64d43A18ef8Ff19E38C1E3A3E30400d7782447B3',
        },
        isETH: true,
        token: serializedTokens.wbnb,
    },
    {
        pid: 4,
        symbol: 'USDC',
        lpSymbol: 'SPYN-USDC',
        lpAddresses: {
            97: '0x296ae37b2366e76A228f439826e5B413465162b4',
            56: '0x026B24780Ad17515f0b2ad500E26F1Da82E5e544',
        },
        contractAddresses: {
            97: '0x7cA263bb1B70146B2994D69EFB321668f297C963',
            56: '0xb8faCF6619598dBdb671e3D178a5012a058d135c',
        },
        isETH: false,
        token: serializedTokens.usdc,
    },
    {
        pid: 5,
        symbol: 'SPY',
        lpSymbol: 'SPYN-SPY',
        lpAddresses: {
            97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
            56: '0x8cce7BE8cBeB16150Efea91CbfDe157bbb7994f5',
        },
        contractAddresses: {
            97: '0x2E1fc6B5F6b9177c84CA753f439B6f18C7fe15ea',
            56: '0x932Eae574dD24be50Ee85e9b20ffBAa7A187A492',
        },
        isETH: false,
        token: serializedTokens.spy,
    },
]

export default yards