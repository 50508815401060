import { SerializedVaultConfig } from 'config/constants/types'
import { SerializedVault } from 'state/types'
import fetchPublicYardData from './fetchPublicYardData'

const fetchYard = async (yard: SerializedVaultConfig, isOld = false): Promise<SerializedVault> => {
  const yardPublicData = await fetchPublicYardData(yard, isOld)

  return { ...yard, ...yardPublicData, isOld }
}

export default fetchYard
