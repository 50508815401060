import BigNumber from 'bignumber.js'
import spyNFTABI from 'config/abi/spyNFT.json'
import spynNFTFactoryABI from 'config/abi/spynNFTFactory.json'
import multicall from 'utils/multicall'
import { getNFTMarketplaceAddress, getNFTSignatureRewardAddress, getSpyNFTFactoryAddress, getSpyNFTRewardAddress, getSpynNFT2FactoryAddress, getSpynNFT2RewardAddress, getSpynNFTFactoryAddress, getSpynNFTRewardAddress } from 'utils/addressHelpers'
import { getFixRate } from 'utils/nftHelpers'
import tokens from 'config/constants/tokens'

export type PublicNFTData = {
    address: string
    id: SerializedBigNumber
    grade: number
    lockedDays: number
    blockNum: SerializedBigNumber
    createdTime: number
    quality: number
    amount: SerializedBigNumber
    realAmount?: SerializedBigNumber
    efficiency: SerializedBigNumber
    staked: boolean
}

export const fetchNFTGegos = async (factoryAddress: string, nftAddress: string,  tokenIds: string[]): Promise<PublicNFTData[]> => {
    
    const calls = tokenIds.map((tokenId) => {
        return { address: factoryAddress, name: 'getGego', params: [tokenId] }
    })

    const rawNFTGegos = await multicall(spynNFTFactoryABI, calls)
    const parsedNFTGegos = rawNFTGegos.map((rawNFTGego, index) => {
        const quality = new BigNumber(rawNFTGego.quality?._hex).toNumber()
        const grade = new BigNumber(rawNFTGego.grade?._hex).toNumber()
        const efficiency = getFixRate(grade, quality).toJSON()
        return {
            address: nftAddress,
            id: tokenIds[index],
            grade,
            lockedDays: new BigNumber(rawNFTGego.lockedDays?._hex).toNumber(),
            blockNum: new BigNumber(rawNFTGego.blockNum?._hex).toJSON(),
            createdTime: new BigNumber(rawNFTGego.createdTime?._hex).toNumber(),
            quality,
            amount: new BigNumber(rawNFTGego.amount?._hex).toJSON(),
            realAmount: new BigNumber(rawNFTGego.realAmount?._hex).toJSON(),
            efficiency,
            staked: false
        }
    })

    return parsedNFTGegos;
}

export const fetchNFTAllowances = async (account: string): Promise<{
  spynFactoryAllowance: boolean, 
  spynRewardAllowance: boolean, 
  spynMarketplaceAllowance: boolean,
  spyn2FactoryAllowance: boolean, 
  spyn2RewardAllowance: boolean, 
  spyn2MarketplaceAllowance: boolean,
  spyFactoryAllowance: boolean, 
  spyRewardAllowance: boolean, 
  spyMarketplaceAllowance: boolean,
  signatureRewardAllowance: boolean,
  signatureMarketplaceAllowance: boolean
}> => {
    const nftMarketplaceAddress = getNFTMarketplaceAddress();
    const spynNftFactoryAddress = getSpynNFTFactoryAddress();
    const spynNFTRewardAddress = getSpynNFTRewardAddress()
    const spynNft2FactoryAddress = getSpynNFT2FactoryAddress();
    const spynNFT2RewardAddress = getSpynNFT2RewardAddress()
    const spyNFTRewardAddress = getSpyNFTRewardAddress()
    const spyNftFactoryAddress = getSpyNFTFactoryAddress()

    const calls = [
        {
            address: tokens.spynnft.address,
            name: 'isApprovedForAll',
            params: [account, spynNftFactoryAddress],
        },
        {
            address: tokens.spynnft.address,
            name: 'isApprovedForAll',
            params: [account, spynNFTRewardAddress],
        },
        {
            address: tokens.spynnft.address,
            name: 'isApprovedForAll',
            params: [account, nftMarketplaceAddress],
        },
        {
            address: tokens.spynnft2.address,
            name: 'isApprovedForAll',
            params: [account, spynNft2FactoryAddress],
        },
        {
            address: tokens.spynnft2.address,
            name: 'isApprovedForAll',
            params: [account, spynNFT2RewardAddress],
        },
        {
            address: tokens.spynnft2.address,
            name: 'isApprovedForAll',
            params: [account, nftMarketplaceAddress],
        },
        {
            address: tokens.spynft.address,
            name: 'isApprovedForAll',
            params: [account, spyNftFactoryAddress],
        },
        {
            address: tokens.spynft.address,
            name: 'isApprovedForAll',
            params: [account, spyNFTRewardAddress],
        },
        {
            address: tokens.spynft.address,
            name: 'isApprovedForAll',
            params: [account, nftMarketplaceAddress],
        },
        {
          address: tokens.signature.address,
          name: 'isApprovedForAll',
          params: [account, getNFTSignatureRewardAddress()],
        },
        {
          address: tokens.signature.address,
          name: 'isApprovedForAll',
          params: [account, nftMarketplaceAddress],
        },
    ];

    const [[spynFactoryAllowance], [spynRewardAllowance], [spynMarketplaceAllowance],[spyn2FactoryAllowance], [spyn2RewardAllowance], [spyn2MarketplaceAllowance], [spyFactoryAllowance], [spyRewardAllowance], [spyMarketplaceAllowance], [signatureRewardAllowance], [signatureMarketplaceAllowance] ] = await multicall(spyNFTABI, calls)

    return {
        spynFactoryAllowance,
        spynRewardAllowance,
        spynMarketplaceAllowance,
        spyn2FactoryAllowance,
        spyn2RewardAllowance,
        spyn2MarketplaceAllowance,
        spyFactoryAllowance,
        spyRewardAllowance,
        spyMarketplaceAllowance,
        signatureRewardAllowance,
        signatureMarketplaceAllowance
    };
}