import React, { lazy, useEffect, useState } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { ResetCSS } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { QueryParamProvider } from 'use-query-params'
import tokens from 'config/constants/tokens'
import useEagerConnect from 'hooks/useEagerConnect'
import useUserAgent from 'hooks/useUserAgent'
import useScrollOnRouteChange from 'hooks/useScrollOnRouteChange'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useLastBannerShownAt, useUserReferrer } from 'state/user/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import { getCurrentReffererFromUrl } from 'utils'
import { deRot13 } from 'utils/encode'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg'
import GlobalCheckClaimStatus from './components/GlobalCheckClaimStatus'
import history from './routerHistory'
// Views included in the main bundle
import Pools from './views/Pools'
import Swap from './views/Swap'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Referral = lazy(() => import('./views/Referral'))
const Vaults = lazy(() => import('./views/Vaults'))
const Yards = lazy(() => import('./views/Yards'))
const Governance = lazy(() => import('./views/Governance'))
const Nfts = lazy(() => import('./views/Nfts'))
// const Signatures = lazy(() => import('./views/Nfts/Signatures'))
const SignatureDashboard = lazy(() => import('./views/SignatureDashboard'))
const SpynNFTPool = lazy(() => import('./views/Nfts/SpynNFTPool'))
const SpyNFTPool = lazy(() => import('./views/Nfts/SpyNFTPool'))
const NftMarketplace = lazy(() => import('./views/NftMarketplace'))
const SpynToken = lazy(() => import('./views/SpynToken'))
const SpynTokenDashboard = lazy(() => import('./views/SpynTokenDashboard'))
const ReferralManagement = lazy(() => import('./views/ReferralManagement'))
const FarmAuction = lazy(() => import('./views/FarmAuction'))
const Lottery = lazy(() => import('./views/Lottery'))
const NotFound = lazy(() => import('./views/NotFound'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Info = lazy(() => import('./views/Info'))
const OldVaults = lazy(() => import('./views/Vaults/OldVaults'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const Banner = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    backdrop-filter: blur(10px);

    display: flex;
    align-items:center;
    justify-content: center;
`

const App: React.FC = () => {
  const { account } = useWeb3React()

  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  useScrollOnRouteChange()
  useUserAgent()

  const [lastBannerShownAt, setLastBannerShownAt] =  useLastBannerShownAt()

  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ResetCSS />
        <GlobalStyle />
        <GlobalCheckClaimStatus excludeLocations={[]} />
        <Menu>
          <SuspenseWithChunkError fallback={<PageLoader />}>
            <Switch>
              <Route path="/" exact>
                <Yards />
              </Route>
              <Route exact path="/farms/auction">
                <FarmAuction />
              </Route>
              <Route path="/farms">
                <Yards />
              </Route>
              <Route path="/auto-pools-B12rTxY">
                <Vaults />
              </Route>
              {/* <Route path="/auto-pools-old">
                <OldVaults />
              </Route> */}
              <Route path="/spyn-nft-pools">
                <SpynNFTPool/>
              </Route>
              <Route path="/sig-dash">
                <SignatureDashboard/>
              </Route>
              { !process.env.REACT_APP_HIDE_SPY_POOLS && (
                <>
                <Route path="/spy-nft-pools">
                  <SpyNFTPool/>
                </Route>
                </>
              )}
              <Route path="/referral">
                <Referral />
              </Route>
              <Route path="/governance">
                <Governance />
              </Route>
              <Route path="/spyn-token">
                <SpynToken />
              </Route>
              <Route path="/referral-management">
                <ReferralManagement />
              </Route>
              <Route path="/spyn-dashboard">
                <SpynTokenDashboard />
              </Route>
              <Route path="/lottery">
                <Lottery />
              </Route>

              {/* NFT */}
              <Route path="/nfts">
                <Nfts nftAddress={tokens.spynnft.address}/>
              </Route>
              <Route path="/nfts-dream">
                <Nfts nftAddress={tokens.spynnft2.address}/>
              </Route>
              {/* <Route path="/signatures">
                <Signatures />
              </Route> */}
              <Route path="/nft-marketplace">
                <NftMarketplace />
              </Route>

              {/* Info pages */}
              <Route path="/info">
                <Info />
              </Route>

              {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
              <Route exact strict path="/swap" component={Swap} />
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
              <Route exact strict path="/find" component={PoolFinder} />
              <Route exact strict path="/liquidity" component={Liquidity} />
              <Route exact strict path="/create" component={RedirectToAddLiquidity} />
              <Route exact path="/add" component={AddLiquidity} />
              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route exact path="/create" component={AddLiquidity} />
              <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
              <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

              {/* Redirect */}
              <Route path="/pool">
                <Redirect to="/liquidity" />
              </Route>
              {/* <Route path="/staking">
                <Redirect to="/auto-pools" />
              </Route>
              <Route path="/syrup">
                <Redirect to="/auto-pools" />
              </Route> */}
              <Route path="/collectibles">
                <Redirect to="/nfts" />
              </Route>

              {/* 404 */}
              <Route component={NotFound} />
            </Switch>
          </SuspenseWithChunkError>
        </Menu>
        <EasterEgg iterations={2} />
        <ToastListener />
        <DatePickerPortal />
      </QueryParamProvider>
    
    </Router>
  )
}

export default React.memo(App)
