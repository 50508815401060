export enum NFTGrade {
    BRONZE = 'Bronze',
    SILVER = 'Silver',
    GOLD = 'Gold',
    RUBY = 'Ruby',
    EMERALD = 'Emerald',
    DIAMOND = "Diamond"
}

export enum SPYNFTGrade {
    CLEANER = 'Cleaner',
    CASHIER = 'Cashier',
    CUSTOMER_SERVICE = 'Customer Service',
    ACCOUNTING = 'Accounting',
    MANAGER = 'Manager',
    CEO = "CEO"
}

export enum SignatureGrade {
    BRONZE = 'Makassar',
    SILVER = 'Palembang',
    GOLD = 'Java',
    RUBY = 'Kalimantan',
    EMERALD = 'Bali',
    DIAMOND = "Jakarta"
}

export interface NFTGradeConfig {
    grade: NFTGrade|SPYNFTGrade|SignatureGrade
    level: number
    image: string
    qualityMin: number
    qualityMax: number
}