import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'
import { BIG_TEN } from 'utils/bigNumber'
import getGasPrice from 'utils/getGasPrice'
import tokens from 'config/constants/tokens'
import { AddressZero } from '@ethersproject/constants'
import { getReferralAddress } from 'utils/addressHelpers'
import {callWithEstimateGas} from './estimateGas'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeFarm = async (masterChefContract, pid, amount, userRefferer) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(pid === 0 ? BIG_TEN.pow(tokens.spyn.decimals) : DEFAULT_TOKEN_DECIMAL).toString()

  let referrer = userRefferer
  if (!userRefferer || !userRefferer.startsWith('0x')) {
    referrer = AddressZero
  }

  const tx = await callWithEstimateGas(masterChefContract, 'deposit', [pid, value, referrer], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeFarm = async (masterChefContract, pid, amount) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(pid === 0 ? BIG_TEN.pow(tokens.spyn.decimals) : DEFAULT_TOKEN_DECIMAL).toString()

  const tx = await callWithEstimateGas(masterChefContract, 'withdraw', [pid, value], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestFarm = async (masterChefContract, pid) => {
  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(masterChefContract, 'deposit', [pid, 0, AddressZero], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const convertErc20ToLP = async(lpConverterContract, tokenAddress: string, amount: string) => {
    const gasPrice = getGasPrice()
  
    const tx = await callWithEstimateGas(lpConverterContract, 'convertERC20', [tokenAddress, amount], {
      gasPrice,
    })

    const receipt = await tx.wait()

    if (receipt.status === 1) {
      /* eslint-disable dot-notation */
      const ev = Array.from(receipt["events"]).filter((v) =>  {
        return v["event"] === "ConvertedToLiquidityPair"
      });

      if (ev.length > 0) {
        const args = ev[0]["args"];

        return new BigNumber(args["liquidityAmount"]._hex).toJSON()
      }
      /* eslint-enable dot-notation */
    }
    return null;
}



export const convertETHToLP = async(lpConverterContract, amount: string) => {
    const gasPrice = getGasPrice()

    const tx = await callWithEstimateGas(lpConverterContract, 'convertETH', [], {
        gasPrice,
    },1000, amount.toString())

    const receipt = await tx.wait()

    if (receipt.status === 1) {
      /* eslint-disable dot-notation */
      const ev = Array.from(receipt["events"]).filter((v) =>  {
        return v["event"] === "ConvertedToLiquidityPair"
      });

      if (ev.length > 0) {
        const args = ev[0]["args"];

        return new BigNumber(args["liquidityAmount"]._hex).toJSON()
      }
      /* eslint-enable dot-notation */
    }
    return null;
}