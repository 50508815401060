import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SerializedReferralState } from 'state/types'
import { fetchPublicReferralData, ReferralPublicData } from './fetchPublicdata'

const initialState: SerializedReferralState = {
    dataLoaded: false
}

export const fetchReferralPublicDataAsync = createAsyncThunk<ReferralPublicData, { account: string}>(
    'referral/fetchReferralPublicDataAsync',
    async ({account}) => {
        const res = await fetchPublicReferralData(account)
        return res
    },
)

export const referralSlice = createSlice({
    name: 'referral',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReferralPublicDataAsync.fulfilled, (state,action) => {
            state.referrerAvailable = action.payload.referrerAvailable
            state.referrerDepth = action.payload.referrerDepth
            state.randomReferrer = action.payload.randomReferrer
            state.currentReferrer = action.payload.currentReferrer
            state.dataLoaded = true
        })
    }
})

export default referralSlice.reducer