import BigNumber from 'bignumber.js'
import { getSpynVaultContract } from 'utils/contractHelpers'
import multicall, { multicallv2 } from 'utils/multicall'
import spynVaultAbi from 'config/abi/spynVault.json'
import { getSpynVaultAddress } from 'utils/addressHelpers'

const spynVaultContract = getSpynVaultContract()

const fetchVaultUser = async (account: string) => {
  try {
    const [userContractResponse, [extraLockIntervalFor]] = await multicall(spynVaultAbi, [
      {
        address: getSpynVaultAddress(),
        name: 'userInfo',
        params: [account],
      },
      {
        address: getSpynVaultAddress(),
        name: 'extraLockIntervalFor',
        params: [account],
      }
    ])
    console.log('here', extraLockIntervalFor)
    return {
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      cakeAtLastUserAction: new BigNumber(userContractResponse.spynAtLastUserAction.toString()).toJSON(),
      extraLockInterval: new BigNumber(extraLockIntervalFor._hex).toJSON()
    }
  } catch (error) {
    console.log('error', error)
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      cakeAtLastUserAction: null,
      extraLockInterval: null
    }
  }
}

export default fetchVaultUser
