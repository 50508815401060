import { serializeTokens } from './tokens'
import { SerializedVaultConfig } from './types'

const serializedTokens = serializeTokens()


export const oldVaults: SerializedVaultConfig[] = [
    {
        pid: 1,
        lpSymbol: 'SPYN-BUSD',
        symbol: 'BUSD',
        lpAddresses: {
            97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
            56: '0x0eabECCcBeF41be0BF3e8453e858B45b3096eDf7',
        },
        contractAddresses: {
            97: '0xd027e323F24ffdA23538947adc693B969642fAA4',
            56: '0x675e2B09755df1E89894fA60E8dFccD128a8c168',
        },
        isETH: false,
        token: serializedTokens.busd,
    },
    {
        pid: 2,
        symbol: 'BNB',
        lpSymbol: 'SPYN-BNB',
        lpAddresses: {
            97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
            56: '0xC0279456619E6F9C3fba4be59458dDC0e02166b2',
        },
        contractAddresses: {
            97: '0xb97E941abf6cdBa748e6bAd82c44ED430C574b28',
            56: '0xDb5634daaA57e711637013765f240e4Eb05C15B2',
        },
        isETH: true,
        token: serializedTokens.wbnb,
    },
    {
        pid: 4,
        symbol: 'USDC',
        lpSymbol: 'SPYN-USDC',
        lpAddresses: {
            97: '0x296ae37b2366e76A228f439826e5B413465162b4',
            56: '0x026B24780Ad17515f0b2ad500E26F1Da82E5e544',
        },
        contractAddresses: {
            97: '0xAB5a1d1e40eBAF11a447CAF09E1F1bE926096bf1',
            56: '0x52881b889625196507cED2Ef09c62AB74C5E3437',
        },
        isETH: false,
        token: serializedTokens.usdc,
    },
    {
        pid: 5,
        symbol: 'SPY',
        lpSymbol: 'SPYN-SPY',
        lpAddresses: {
            97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
            56: '0xc115f337130aaaE97F4Ab34751ee2854D2795958',
        },
        contractAddresses: {
            97: '0xf17293132b9fFb529D5b3dCb615aF94F10efaD81',
            56: '0x51dfDAe6fc9a54A97cEDC1C27cDC1fccC73afcF3',
        },
        isETH: false,
        token: serializedTokens.spy,
    }
]


const vaults: SerializedVaultConfig[] = [
    {
        pid: 1,
        lpSymbol: 'SPYN-BUSD',
        symbol: 'BUSD',
        lpAddresses: {
            97: '0xF9addC84D1D61c4261F797089e55c1E0920874d3',
            56: '0x0eabECCcBeF41be0BF3e8453e858B45b3096eDf7',
        },
        contractAddresses: {
            97: '0x73061Ddca125F8BD1C7bCE7DAa680dC53eAA4236',
            56: '0x21003188F535F357A2e92Ec712505fB242558253',
        },
        isETH: false,
        token: serializedTokens.busd,
    },
    {
        pid: 2,
        symbol: 'BNB',
        lpSymbol: 'SPYN-BNB',
        lpAddresses: {
            97: '0x2a4a6f0163900d30469e780cb8739fc4df8cbecf',
            56: '0xC0279456619E6F9C3fba4be59458dDC0e02166b2',
        },
        contractAddresses: {
            97: '0x83e3Ef5453751C9F80c5685F1481D4be5E2857f3',
            56: '0xcD125155eFc2Cc25B12ABE39027Bf0a9ee4d38df',
        },
        isETH: true,
        token: serializedTokens.wbnb,
    },
    {
        pid: 4,
        symbol: 'USDC',
        lpSymbol: 'SPYN-USDC',
        lpAddresses: {
            97: '0x296ae37b2366e76A228f439826e5B413465162b4',
            56: '0x026B24780Ad17515f0b2ad500E26F1Da82E5e544',
        },
        contractAddresses: {
            97: '0x8652f99D88F64Bb61c0Bb0cDd8F70c2c9b3Aea3f',
            56: '0x57cCECFfBD29461D213440BEd1DDF1EF199555CE',
        },
        isETH: false,
        token: serializedTokens.usdc,
    },
    {
        pid: 5,
        symbol: 'SPY',
        lpSymbol: 'SPYN-SPY',
        lpAddresses: {
            97: '0xf63939219f12D687cb8BC7019D3C2831d1C3dA68',
            56: '0x8cce7BE8cBeB16150Efea91CbfDe157bbb7994f5',
        },
        contractAddresses: {
            97: '0xAD71056d403EF3ff834Eb0FaC521a5B9Eb0631c4',
            56: '0xdbeF45b7f2bd28F0490eB6695377f45004EF0129',
        },
        isETH: false,
        token: serializedTokens.spy,
    },
]

export default vaults