import BigNumber from 'bignumber.js'
import { AddressZero } from '@ethersproject/constants'
import getGasPrice from 'utils/getGasPrice'
import { callWithEstimateGas } from 'utils/calls/estimateGas'
import tokens from 'config/constants/tokens'
import { getSpyNFTContract } from 'utils/contractHelpers'
import { Token } from '@pancakeswap/sdk'
import { DeserializedNFTGego } from 'state/types'
import { nftGrades } from 'config/constants/nft'
import { NFTGradeConfig } from 'config/constants/nft/types'
import { BIG_TEN } from 'utils/bigNumber'


export type CastedNFTData = {
  id: string,
  amount: BigNumber,
  blockNum: BigNumber,
  grade: number,
  createdTime: number,
  lockedDays: number,
  quality: number
  resBaseId?: BigNumber
  expiring?: BigNumber
}

export const isAddressSpynNft2 = (address?: string) : boolean => {
  return address?.toLowerCase() === tokens.spynnft2.address.toLowerCase()
}

export const isAddressSpynNft = (address?: string) : boolean => {
  return address?.toLowerCase() === tokens.spynnft.address.toLowerCase()
}

export const isAddressSignatureNft = (address: string) : boolean => {
  return address.toLowerCase() === tokens.signature.address.toLowerCase()
}

export const getGradeConfig = (gego: DeserializedNFTGego) : NFTGradeConfig | undefined => {
    if (isAddressSignatureNft(gego.address)) {
        const grades = nftGrades(gego.address)
        return grades.find((c) => c.level === gego.resBaseId?.toNumber() + 1) ?? grades[0]
    }

    return nftGrades(gego.address).find((c) => c.level === gego.grade)
}

export const getTokenFromNFTAddress = (nftAddress: string) : Token => {
    if (isAddressSignatureNft(nftAddress)) return tokens.spyn
    if (isAddressSpynNft(nftAddress)) return tokens.spyn
    return tokens.spy
}

export const adminCastNFTs = async (
  nftFactory, 
  quantity:string|number, 
  amount: string, 
  grade: string|number, 
  quality: string|number, 
  lockedDays: string|number, 
  author: string) => {

  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(nftFactory, 'gmMintMulti', [quantity, amount, grade, quality, lockedDays, author], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt
}

export const castNFT = async (nftFactory, tokenAmount): Promise<CastedNFTData|null> => {

    const gasPrice = getGasPrice()
    const value = new BigNumber(tokenAmount).toString()
  
    const tx = await callWithEstimateGas(nftFactory, 'mint', [[value, 0, 0, 0]], {
      gasPrice,
    })
    const receipt = await tx.wait()

    if (receipt.status === 1) {
      /* eslint-disable dot-notation */
      const ev = Array.from(receipt["events"]).filter((v) =>  {
        return v["event"] === "GegoAdded"
      });

      if (ev.length > 0) {
        const args = ev[0]["args"];

        return {
          id: new BigNumber(args["id"]._hex).toJSON(),
          amount: new BigNumber(args["amount"]._hex),
          blockNum: new BigNumber(args["blockNum"]._hex),
          grade: new BigNumber(args["grade"]._hex).toNumber(),
          createdTime: new BigNumber(args["createdTime"]._hex).toNumber(),
          lockedDays: new BigNumber(args["lockedDays"]._hex).toNumber(),
          quality: new BigNumber(args["quality"]._hex).toNumber(),
          resBaseId: new BigNumber(args["amount"]._hex),
        }
      }
      /* eslint-enable dot-notation */
    }
    return null;
}

export const burnNFT = async (nftFactory, tokenId) => {

  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(nftFactory, 'burn', [tokenId], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const stakeNFT = async (nftReward, tokenId, userReferrer) => {

  let referrer = userReferrer
  if (!userReferrer || !userReferrer.startsWith('0x')) {
    referrer = AddressZero
  }

  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(nftReward, 'stake', [tokenId, referrer], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const stakeNFTMulti = async (nftReward, tokenIds, userReferrer) => {

  let referrer = userReferrer
  if (!userReferrer || !userReferrer.startsWith('0x')) {
    referrer = AddressZero
  }

  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(nftReward, 'stakeMulti', [tokenIds, referrer], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeNFT = async (nftReward, tokenId) => {

  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(nftReward, 'unstake', [tokenId], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const exitNFT = async (nftReward) => {

  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(nftReward, 'exit', [], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestNFT = async (nftReward) => {

  const gasPrice = getGasPrice()

  const tx = await callWithEstimateGas(nftReward, 'harvest', [], {
    gasPrice,
  })
  const receipt = await tx.wait()
  /* eslint-disable dot-notation */
  if (receipt.status === 1) {
    const ev = Array.from(receipt["events"]).filter((v) =>  {
        return v["event"] === "RewardPaid"
    });

    if (ev.length > 0) {
        const args = ev[0]["args"];
        return new BigNumber(args["reward"]._hex)
    }
  }
  /* eslint-enable dot-notation */
  return null
}


export const castNFTSignature = async (nftFactory, spyAmount, resId): Promise<CastedNFTData|null> => {

    const gasPrice = getGasPrice()
    const value = new BigNumber(spyAmount).toString()
    const resBaseId = new BigNumber(resId).toString()

    const tx = await callWithEstimateGas(nftFactory, 'mint', [[value, resBaseId, 0, 0, 0]], {
      gasPrice,
    })
    const receipt = await tx.wait()

    if (receipt.status === 1) {
      /* eslint-disable dot-notation */
      const ev = Array.from(receipt["events"]).filter((v) =>  {
        return v["event"] === "GegoAdded"
      });

      if (ev.length > 0) {
        const args = ev[0]["args"];

        return {
          id: new BigNumber(args["id"]._hex).toJSON(),
          amount: new BigNumber(args["amount"]._hex),
          blockNum: new BigNumber(args["blockNum"]._hex),
          grade: new BigNumber(args["grade"]._hex).toNumber(),
          createdTime: new BigNumber(args["createdTime"]._hex).toNumber(),
          lockedDays: 0,
          resBaseId: new BigNumber(args["amount"]._hex),
          expiring: new BigNumber(args["expiringTime"]._hex),
          quality: new BigNumber(args["quality"]._hex).toNumber(),
        }
      }
      /* eslint-enable dot-notation */
    }
    return null;
}


export const purchaseNFTSignature = async (nftFactory, ruleId, amount) => {

  const gasPrice = getGasPrice()
  const amountNumber = new BigNumber(amount).toString()
  const ruleIdNumber = new BigNumber(ruleId).toString()
  const resBaseIds = [];
  for (let i = 0; i < amount; i ++) {
    resBaseIds.push(new BigNumber(Math.floor(Math.random() * 6)).toString())
  }

  const tx = await callWithEstimateGas(nftFactory, 'buy', [new BigNumber(0).toString(), ruleIdNumber, amountNumber, resBaseIds], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const injectNFTSignature = async (nftFactory, gegoId, spyAmount) => {

  const gasPrice = getGasPrice()
  const value = new BigNumber(spyAmount).toString()

  const tx = await callWithEstimateGas(nftFactory, 'inject', [gegoId, spyAmount], {
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

export const castNFTSignatureAdmin = async (
    nftFactory, 
    ruleId:string|number, 
    resBaseIds: string[]|number[],
    quantity: string|number,
    grade: string|number,
    quality: string|number,
    author: string
) => {
    const gasPrice = getGasPrice()

    const tx = await callWithEstimateGas(nftFactory, 'gmMintMulti', [ruleId, resBaseIds, quantity, grade, quality, 365*86400, author], {
        gasPrice,
    })
    const receipt = await tx.wait()
    return receipt
  }

export const createNFTSignatureMintRule = async(contract, ruleId: string|number, maxQuantityPerBatch: string|number, maxQuantityPerClick: string|number, maxSpynAmount: string, expiration: string|number) => {
    const gasPrice = getGasPrice()
    const tx = await callWithEstimateGas(contract, 'setRuleData', [
        ruleId, 
        maxSpynAmount,
        new BigNumber(35).multipliedBy(BIG_TEN.pow(18)).toString(),
        new BigNumber(50).multipliedBy(BIG_TEN.pow(18)).toString(),
        10,
        tokens.spyn.address,
        tokens.busd.address,
        maxQuantityPerClick,
        maxQuantityPerBatch,
        expiration,
        true,
    ], {
        gasPrice,
      })
    const receipt = await tx.wait()
    return receipt.status
}

export const activateNFTSignatureMintRule = async(contract, ruleId: string|number) => {
    const gasPrice = getGasPrice()
    const tx = await callWithEstimateGas(contract, 'setCurrentRuleId', [ruleId], {
        gasPrice,
    })
    const receipt = await tx.wait()
    return receipt.status
}