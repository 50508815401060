import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { SerializedReferralState } from 'state/types'
import { useWeb3React } from '@web3-react/core'
import useRefresh from 'hooks/useRefresh'
import { fetchReferralPublicDataAsync } from '.'
import { State } from '../types'

export const usePollReferralPublicData = () => {
    const dispatch = useAppDispatch()
    const { slowRefresh } = useRefresh()
    const { account } = useWeb3React()
    useEffect(() => {
        if (account) {
            dispatch(fetchReferralPublicDataAsync({account}))
        }
    }, [dispatch, slowRefresh, account])
}

export const useReferralData = () : SerializedReferralState => {
    const referral = useSelector((state: State) => state.referral)
  
    return referral
  }