import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import tokens from 'config/constants/tokens'
import multicall from 'utils/multicall'
import { getNFT2MintroxyAddress, getNFTMintroxyAddress, getNFTSignatureMintProxyAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import { getNFTSignatureMinProxyContract } from 'utils/contractHelpers'

type PublicNFTData = {
    tokenAmountTotal: SerializedBigNumber
    nftCastAllowance: SerializedBigNumber
    nft2CastAllowance: SerializedBigNumber
    nftSignatureCastAllowance: SerializedBigNumber
}

type PublicNFTFactoryData = {
    activeRuleId: SerializedBigNumber
    maxMintAmount: SerializedBigNumber
    maxMintQuantity: SerializedBigNumber
    maxMintQuantityPerClick: SerializedBigNumber
    mintCost: SerializedBigNumber
    mintCostDiscount: SerializedBigNumber
    mintCostDiscountQuantity: SerializedBigNumber
    mintedQuantity: SerializedBigNumber
    costToken: string
}


export const fetchPublicNFTData = async(account: string): Promise<PublicNFTData> =>  {

    const calls = [
        {
          address: tokens.spyn.address,
          name: 'balanceOf',
          params: [account],
        },
        {
            address: tokens.spyn.address,
            name: 'allowance',
            params: [account, getNFTMintroxyAddress()],
        },
        {
            address: tokens.spyn.address,
            name: 'allowance',
            params: [account, getNFT2MintroxyAddress()],
        },
        {
            address: tokens.spyn.address,
            name: 'allowance',
            params: [account, getNFTSignatureMintProxyAddress()],
        },
        {
          address: tokens.spyn.address,
          name: 'decimals',
        },
    ];

    const [tokenBalance, nftCastAllowance, nft2CastAllowance, nftSignatureCastAllowance, spyDecimals] =
    await multicall(erc20, calls)

    const tokenAmountTotal = new BigNumber(tokenBalance).div(BIG_TEN.pow(spyDecimals))
    const nftCastAllowanceTotal = new BigNumber(nftCastAllowance).div(BIG_TEN.pow(spyDecimals))
    const nft2CastAllowanceTotal = new BigNumber(nft2CastAllowance).div(BIG_TEN.pow(spyDecimals))
    const nftSignatureCastAllowanceTotal = new BigNumber(nftSignatureCastAllowance).div(BIG_TEN.pow(spyDecimals))


    return {
        tokenAmountTotal: tokenAmountTotal.toJSON(),
        nftSignatureCastAllowance: nftSignatureCastAllowanceTotal.toJSON(),
        nft2CastAllowance: nft2CastAllowanceTotal.toJSON(),
        nftCastAllowance: nftCastAllowanceTotal.toJSON()
    };
}

export const fetchNFTSignatureFactoryData = async (): Promise<PublicNFTFactoryData> => {
    const mintProxyContract = getNFTSignatureMinProxyContract();
    const ruleData = await mintProxyContract.getActiveRuleData();
    const activeRuleId = new BigNumber(ruleData.ruleId._hex).toJSON();
    const maxMintAmount = new BigNumber(ruleData.maxMintAmount._hex).toJSON();
    const maxMintQuantity = new BigNumber(ruleData.maxQuantityPerBatch._hex).toJSON();
    const maxMintQuantityPerClick = new BigNumber(ruleData.maxQuantityPerClick._hex).toJSON();
    const mintCost = new BigNumber(ruleData.costErc20Amount._hex).toJSON();
    const mintCostDiscount = new BigNumber(ruleData.costErc20Discount._hex).toJSON();
    const mintCostDiscountQuantity = new BigNumber(ruleData.costErc20DiscountQunatity._hex).toJSON();
    const mintedQuantity = new BigNumber(ruleData.mintedQuantity._hex).toJSON();
    const costToken = ruleData.costErc20;

    return {
        activeRuleId,
        maxMintAmount,
        maxMintQuantity,
        maxMintQuantityPerClick,
        mintCost,
        mintCostDiscount,
        mintCostDiscountQuantity,
        mintedQuantity,
        costToken
    };
}