import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.spyn,
    earningToken: serializedTokens.spyn,
    contractAddress: {
      97: '0xD0D0460b3584CBcB137Bf751D10491631bE365Da',
      56: '0x19a20c407fBFE423Fec3f45a3160e309FC580503',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    isFinished: false,
  }
]

export default pools