import { SerializedVaultConfig } from 'config/constants/types'
import { SerializedFarm } from 'state/types'
import fetchYard from './fetchYard'

const fetchYards = async (yardsToFetch: SerializedVaultConfig[], isOld = false) => {
  const data = await Promise.all(
    yardsToFetch.map(async (yardConfig) => {
      const yard = await fetchYard(yardConfig, isOld)
      return yard
    }),
  )
  return data
}

export default fetchYards
