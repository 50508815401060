
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import tokens from 'config/constants/tokens'
import { SerializedNFTState, SerialzedNFTFactoryPublicData } from 'state/types'
import { getSpyNFTFactoryAddress, getSpyNFTRewardAddress, getSpynNFT2FactoryAddress, getSpynNFT2RewardAddress, getSpynNFTFactoryAddress, getSpynNFTRewardAddress } from 'utils/addressHelpers'
import { fetchNFTBalance, fetchStakedSpyNFTs, fetchStakedSpynNFT2s, fetchStakedSpynNFTs } from './fetchNFT'
import { fetchNFTGegos, fetchNFTAllowances, PublicNFTData } from './fetchNFTData'
import { fetchGeneralNFTRewardUserData, fetchGeneralNFTRewardPublicData, PublicNFTRewardUserData, PublicNFTRewardPoolData } from './fetchNFTReward'
import { fetchNFTSignatureBalance, fetchStakedNFTSignatures } from './fetchNFTSignature'
import { fetchNFTSignatureGegos } from './fetchNFTSignatureData'
import { fetchNFTSignatureRewardPublicData, fetchNFTSignatureRewardUserData } from './fetchNFTSignatureReward'
import { fetchNFTSignatureFactoryData, fetchPublicNFTData } from './fetchPublicNFTData'

const initialState: SerializedNFTState = {
    userDataLoaded: false,
    loadArchivedData: false,
    spynNftBalance: [],
    spynNft2Balance: [],
    spyNftBalance: [],
    signatureBalance: []
}

interface NFTUserDataResponse {
    spynBalance: string
    castNFTAllowance: string
    castNFT2Allowance: string
    castNFTSignatureAllowance: string
}

interface NFTAllowanceDataResponse {
    spynFactoryAllowance: boolean
    spynRewardAllowance: boolean
    spynMarketplaceAllowance: boolean
    spyn2FactoryAllowance: boolean
    spyn2RewardAllowance: boolean
    spyn2MarketplaceAllowance: boolean
    spyFactoryAllowance: boolean
    spyRewardAllowance: boolean
    spyMarketplaceAllowance: boolean
    signatureRewardAllowance: boolean
    signatureMarketplaceAllowance: boolean
}

export const fetchNFTUserDataAsync = createAsyncThunk<NFTUserDataResponse, { account: string}>(
    'nft/fetchNFTUserDataAsync',
    async ({account}) => {
        const publicData = await fetchPublicNFTData(account)
        return {
            spynBalance: publicData.tokenAmountTotal,
            castNFT2Allowance: publicData.nft2CastAllowance,
            castNFTSignatureAllowance: publicData.nftSignatureCastAllowance,
            castNFTAllowance: publicData.nftCastAllowance
        }
    },
)

export const fetchNFTPoolPublicDataAsync = createAsyncThunk<{spyn: PublicNFTRewardPoolData, spy: PublicNFTRewardPoolData, spyn2: PublicNFTRewardPoolData}>(
    'nft/fetchNFTPoolPublicDataAsync',
    async () => {
        const spyn = await fetchGeneralNFTRewardPublicData(getSpynNFTRewardAddress())
        const spyn2 = await fetchGeneralNFTRewardPublicData(getSpynNFT2RewardAddress())
        // const spy = await fetchGeneralNFTRewardPublicData(getSpyNFTRewardAddress())
        return {spyn, spy: undefined, spyn2}
    },
)

export const fetchNFTPoolUserDataAsync = createAsyncThunk<{spyn: PublicNFTRewardUserData, spy:PublicNFTRewardUserData, spyn2: PublicNFTRewardUserData}, { account: string}>(
    'nft/fetchNFTPoolUserDataAsync',
    async ({account}) => {
        const spyn = await fetchGeneralNFTRewardUserData(account, getSpynNFTRewardAddress())
        const spyn2 = await fetchGeneralNFTRewardUserData(account, getSpynNFT2RewardAddress())
        // const spy = await fetchGeneralNFTRewardUserData(account, getSpyNFTRewardAddress())
        return {spyn, spy: undefined, spyn2}
    },
)

export const fetchNFTUserBalanceDataAsync = createAsyncThunk<{spyn: PublicNFTData[], spy: PublicNFTData[], spyn2: PublicNFTData[]}, { account: string}>(
    'nft/fetchNFTuserBalanceDataAsync',
    async ({account}) => {
        const spynNftIds = await fetchNFTBalance(tokens.spynnft.address, account);
        const spynNftIdsStaked = await fetchStakedSpynNFTs(account);
        const spynNftGegos = await fetchNFTGegos(getSpynNFTFactoryAddress(), tokens.spynnft.address, [...spynNftIds.filter((id) => id !== '0'), ...spynNftIdsStaked.filter((id) => id !== '0')])
        const spynNft2Ids = await fetchNFTBalance(tokens.spynnft2.address, account);
        const spynNft2IdsStaked = await fetchStakedSpynNFT2s(account);
        const spynNft2Gegos = await fetchNFTGegos(getSpynNFT2FactoryAddress(), tokens.spynnft2.address, [...spynNft2Ids.filter((id) => id !== '0'), ...spynNft2IdsStaked.filter((id) => id !== '0')])
        const unstakedSpynGegos = spynNftGegos.filter((gego, index) =>  {
            return spynNftIds.indexOf(gego.id) !== -1;
        })
        const stakedSpynGegos = spynNftGegos.filter((gego, index) =>  {
            return spynNftIdsStaked.indexOf(gego.id) !== -1;
        })
        const unstakedSpyn2Gegos = spynNft2Gegos.filter((gego, index) =>  {
            return spynNft2Ids.indexOf(gego.id) !== -1;
        })
        const stakedSpyn2Gegos = spynNft2Gegos.filter((gego, index) =>  {
            return spynNft2IdsStaked.indexOf(gego.id) !== -1;
        })
        unstakedSpynGegos.forEach((gego, index) => {
            unstakedSpynGegos[index].staked = false;
        })
        stakedSpynGegos.forEach((gego, index) => {
            stakedSpynGegos[index].staked = true;
        })
        unstakedSpyn2Gegos.forEach((gego, index) => {
            unstakedSpyn2Gegos[index].staked = false;
        })
        stakedSpyn2Gegos.forEach((gego, index) => {
            stakedSpyn2Gegos[index].staked = true;
        })
        return { spyn: [...unstakedSpynGegos, ...stakedSpynGegos], spy: [], spyn2: [...unstakedSpyn2Gegos, ...stakedSpyn2Gegos]};
    },
)

export const fetchNFTSignatureFactoryPublicDataAsync = createAsyncThunk<SerialzedNFTFactoryPublicData>(
    'nft/fetchNFTSignatureFactoryPublicDataAsync',
    async () => {
        const publicData = await fetchNFTSignatureFactoryData()
        return publicData
    },
)

export const fetchNFTSignaturePoolPublicDataAsync = createAsyncThunk<PublicNFTRewardPoolData>(
    'nft/fetchNFTSignaturePoolPublicDataAsync',
    async () => {
        const publicData = await fetchNFTSignatureRewardPublicData()
        return publicData
    },
)

export const fetchNFTSignaturePoolUserDataAsync = createAsyncThunk<PublicNFTRewardUserData, { account: string}>(
    'nft/fetchNFTSignaturePoolUserDataAsync',
    async ({account}) => {
        const userData = await fetchNFTSignatureRewardUserData(account)
        return userData
    },
)

export const fetchNFTSignatureUserBalanceDataAsync = createAsyncThunk<PublicNFTData[], { account: string}>(
    'nft/fetchNFTSignatureUserBalanceDataAsync',
    async ({account}) => {
        const tokenIds = await fetchNFTSignatureBalance(account);
        const tokenIdsStaked = await fetchStakedNFTSignatures(account);
        const tokenGegos = await fetchNFTSignatureGegos([...tokenIds.filter((id) => id !== '0'), ...tokenIdsStaked.filter((id) => id !== '0')]);
        const unstakedGegos = tokenGegos.filter((gego, index) =>  {
            return tokenIds.indexOf(gego.id) !== -1;
        })
        const stakedGegos = tokenGegos.filter((gego, index) =>  {
            return tokenIdsStaked.indexOf(gego.id) !== -1;
        })
        unstakedGegos.forEach((gego, index) => {
            unstakedGegos[index].staked = false;
        })
        stakedGegos.forEach((gego, index) => {
            stakedGegos[index].staked = true;
        })
        return [...unstakedGegos, ...stakedGegos];
    },
)

export const fetchNFTAllowancesAsync = createAsyncThunk<NFTAllowanceDataResponse, { account: string}>(
    'nft/fetchNFTAllowancesAsync',
    async ({account}) => {
        const allowances = await fetchNFTAllowances(account)
        return allowances;
    },
)

export const nftSlice = createSlice({
    name: 'nftv2',
    initialState,
    reducers: {
        setLoadArchivedNFTData: (state, action) => {
            const loadArchivedData = action.payload
            state.loadArchivedData = loadArchivedData
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNFTUserDataAsync.fulfilled, (state,action) => {
            state.spynBalance = action.payload.spynBalance
            state.castNFTAllowance = action.payload.castNFTAllowance
            state.castNFT2Allowance  = action.payload.castNFT2Allowance
            state.castSignatureAllowance = action.payload.castNFTSignatureAllowance
        })
        builder.addCase(fetchNFTPoolPublicDataAsync.fulfilled, (state,action) => {
            state.spynPoolPublicData = {
                ...state.spynPoolPublicData,
                ...action.payload.spyn
            }
            state.spyPoolPublicData = {
                ...state.spyPoolPublicData,
                ...action.payload.spy
            }
            state.spyn2PoolPublicData = {
                ...state.spyn2PoolPublicData,
                ...action.payload.spyn2
            }
        })
        builder.addCase(fetchNFTPoolUserDataAsync.fulfilled, (state,action) => {
            state.spynPoolUserData = {
                ...state.spynPoolUserData,
                ...action.payload.spyn,
                userDataLoaded: true
            }
            state.spyn2PoolUserData = {
                ...state.spyn2PoolUserData,
                ...action.payload.spyn2,
                userDataLoaded: true
            }
            state.spyPoolUserData = {
                ...state.spyPoolUserData,
                ...action.payload.spy,
                userDataLoaded: true
            }
        })
        builder.addCase(fetchNFTUserBalanceDataAsync.fulfilled, (state,action) => {
            state.spynNftBalance = action.payload.spyn.map((gego) => {
                return {
                    ...gego
                }
            })
            state.spynNft2Balance = action.payload.spyn2.map((gego) => {
                return {
                    ...gego
                }
            })
            state.spyNftBalance = action.payload.spy.map((gego) => {
                return {
                    ...gego
                }
            })
        })
        builder.addCase(fetchNFTAllowancesAsync.fulfilled, (state,action) => {
            state.spynFactoryAllowance = action.payload.spynFactoryAllowance
            state.spynRewardAllowance = action.payload.spynRewardAllowance
            state.spynMarketplaceAllowance = action.payload.spynMarketplaceAllowance
            state.spyn2FactoryAllowance = action.payload.spyn2FactoryAllowance
            state.spyn2RewardAllowance = action.payload.spyn2RewardAllowance
            state.spyn2MarketplaceAllowance = action.payload.spyn2MarketplaceAllowance
            state.spyFactoryAllowance = action.payload.spyFactoryAllowance
            state.spyRewardAllowance = action.payload.spyRewardAllowance
            state.spyMarketplaceAllowance = action.payload.spyMarketplaceAllowance
            state.signatureRewardAllowance = action.payload.signatureRewardAllowance
            state.signatureMarketplaceAllowance = action.payload.signatureMarketplaceAllowance
        })


        builder.addCase(fetchNFTSignatureFactoryPublicDataAsync.fulfilled, (state, action) => {
            state.signatureFactoryData = {
                ...state.signatureFactoryData,
                ...action.payload
            }
        })

        builder.addCase(fetchNFTSignaturePoolUserDataAsync.fulfilled, (state,action) => {
            state.signaturePoolUserData = {
                ...state.signaturePoolUserData,
                ...action.payload,
                userDataLoaded: true
            }
        })
        builder.addCase(fetchNFTSignaturePoolPublicDataAsync.fulfilled, (state,action) => {
            state.signaturePoolPublicData = {
                ...state.signaturePoolPublicData,
                ...action.payload
            }
        })
        builder.addCase(fetchNFTSignatureUserBalanceDataAsync.fulfilled, (state,action) => {
            state.signatureBalance = action.payload.map((gego) => {
                return {
                    ...gego
                }
            })
        })
    }
})

export const {setLoadArchivedNFTData } = nftSlice.actions
export default nftSlice.reducer