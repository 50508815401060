import tokens from "../tokens";
import { NFTGrade, NFTGradeConfig, SignatureGrade, SPYNFTGrade } from "./types";

export const spynNftGrades: NFTGradeConfig[] = [
    {
        grade: NFTGrade.BRONZE,
        level: 1,
        image: 'bronze.jpg',
        qualityMin: 0,
        qualityMax: 5000
    },
    {
        grade: NFTGrade.SILVER,
        level: 2,
        image: 'silver.jpg',
        qualityMin: 5000,
        qualityMax: 8000
    },
    {
        grade: NFTGrade.GOLD,
        level: 3,
        image: 'gold.jpg',
        qualityMin: 8000,
        qualityMax: 9000
    },
    {
        grade: NFTGrade.RUBY,
        level: 4,
        image: 'ruby.jpg',
        qualityMin: 9000,
        qualityMax: 9800
    },
    {
        grade: NFTGrade.EMERALD,
        level: 5,
        image: 'emerald.jpg',
        qualityMin: 9800,
        qualityMax: 9980
    },
    {
        grade: NFTGrade.DIAMOND,
        level: 6,
        image: 'diamond.jpg',
        qualityMin: 9980,
        qualityMax: 10000
    }
]

export const spyNftGrades: NFTGradeConfig[] = [
    {
        grade: SPYNFTGrade.CLEANER,
        level: 1,
        image: 'cleaner.png',
        qualityMin: 0,
        qualityMax: 5000
    },
    {
        grade: SPYNFTGrade.CASHIER,
        level: 2,
        image: 'cashier.png',
        qualityMin: 5000,
        qualityMax: 8000
    },
    {
        grade: SPYNFTGrade.CUSTOMER_SERVICE,
        level: 3,
        image: 'customer_service.png',
        qualityMin: 8000,
        qualityMax: 9000
    },
    {
        grade: SPYNFTGrade.ACCOUNTING,
        level: 4,
        image: 'accounting.png',
        qualityMin: 9000,
        qualityMax: 9800
    },
    {
        grade: SPYNFTGrade.MANAGER,
        level: 5,
        image: 'manager.png',
        qualityMin: 9800,
        qualityMax: 9980
    },
    {
        grade: SPYNFTGrade.CEO,
        level: 6,
        image: 'ceo.png',
        qualityMin: 9980,
        qualityMax: 10000
    }
]

export const spynSignatureCardNames = [
    'Makassar',
    'Palembang',
    'Java',
    'Kalimantan',
    'Bali',
    "Jakarta"]

export const spynSignatureGrades: NFTGradeConfig[] = [
    {
        grade: SignatureGrade.BRONZE,
        level: 1,
        image: 'sig-0.jpg',
        qualityMin: 0,
        qualityMax: 5000
    },
    {
        grade: SignatureGrade.SILVER,
        level: 2,
        image: 'sig-1.jpg',
        qualityMin: 5000,
        qualityMax: 8000
    },
    {
        grade: SignatureGrade.GOLD,
        level: 3,
        image: 'sig-2.jpg',
        qualityMin: 8000,
        qualityMax: 9000
    },
    {
        grade: SignatureGrade.RUBY,
        level: 4,
        image: 'sig-3.jpg',
        qualityMin: 9000,
        qualityMax: 9800
    },
    {
        grade: SignatureGrade.EMERALD,
        level: 5,
        image: 'sig-4.jpg',
        qualityMin: 9800,
        qualityMax: 9980
    },
    {
        grade: SignatureGrade.DIAMOND,
        level: 6,
        image: 'sig-5.jpg',
        qualityMin: 9980,
        qualityMax: 10000
    }
]

export const nftGrades = (address: string|undefined) => {
    if (address?.toLowerCase() === tokens.spynft.address.toLowerCase()) {
        return spyNftGrades
    }

    if (address?.toLowerCase() === tokens.signature.address.toLowerCase()) {
        return spynSignatureGrades
    }

    if (address?.toLowerCase() === tokens.spynnft2.address.toLowerCase()) {
        return spynSignatureGrades
    }

    return spynNftGrades
}