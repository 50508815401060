import React, { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
} from '@pancakeswap/uikit'
import history from 'routerHistory'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'state/profile/hooks'
import { AddressZero } from '@ethersproject/constants'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { usePollReferralPublicData, useReferralData } from 'state/referral/hooks'
import { useRandomReferralModalShown, useUserReferrer } from 'state/user/hooks'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import { getCurrentReffererFromUrl, isAddress } from 'utils'
import { deRot13 } from 'utils/encode'
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
import ProfileUserMenuItem from './ProfileUserMenutItem'
import WalletUserMenuItem from './WalletUserMenuItem'
import { getFullDisplayBalance, formatBigNumber } from '../../../utils/formatBalance'
import tokens from '../../../config/constants/tokens'
import RandomReferralModal from './RandomReferralModal'


const UserMenu = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const [userRefferer, setUserReferrer] = useUserReferrer()
  const [randomReferralModalShown, setRandomReferralModalShown] = useRandomReferralModalShown()
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const {randomReferrer, currentReferrer: referrerRegistered, dataLoaded: referralDataLoaded} = useReferralData()
  // const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentRandomReferralModal] = useModal(<RandomReferralModal referrer={randomReferrer}/>, false, true, 'randomReferrerSuggestion')
  // const hasProfile = isInitialized && !!profile
  // const avatarSrc = profile?.nft?.image?.thumbnail
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)
  const { balance: spynBalance, fetchStatus: spyFetchStatus } = useTokenBalance(tokens.spyn.address)

  useEffect(() => {
    const referrer = getCurrentReffererFromUrl()
    const referrerDecoded = deRot13(referrer);
    if (referrerDecoded.startsWith('0x')) {
      const formattedAddress = isAddress(referrerDecoded)
      console.log('here', formattedAddress)
      if (formattedAddress) {
        setUserReferrer(referrerDecoded);
      } else {
        setUserReferrer(undefined)
      }
    }
  }, [setUserReferrer])

  useEffect(() => {
    if (referralDataLoaded && account && !randomReferralModalShown && !userRefferer && randomReferrer && randomReferrer !== AddressZero && randomReferrer.toLowerCase() !== account.toLowerCase() && referrerRegistered && referrerRegistered === AddressZero) {
      setRandomReferralModalShown(true)
      onPresentRandomReferralModal()
    }
  }, [referralDataLoaded, randomReferrer, userRefferer, referrerRegistered, account, randomReferralModalShown, setRandomReferralModalShown, onPresentRandomReferralModal])

  usePollReferralPublicData()

  if (!account) {
    return <ConnectWalletButton scale="sm" />
  }

  return (
    // <UIKitUserMenu account={account} avatarSrc={avatarSrc}>
    <UIKitUserMenu text={getFullDisplayBalance(spynBalance, tokens.spyn.decimals, 0)}>
      <WalletUserMenuItem hasLowBnbBalance={hasLowBnbBalance} onPresentWalletModal={onPresentWalletModal} />
      {/* <UserMenuItem as="button" onClick={onPresentTransactionModal}>
        {t('Transactions')}
      </UserMenuItem>
      <UserMenuDivider />
      <UserMenuItem as="button" onClick={() => history.push(`${nftsBaseUrl}/profile/${account.toLowerCase()}`)}>
        {t('Your NFTs')}
      </UserMenuItem>
      <ProfileUserMenuItem isLoading={isLoading} hasProfile={hasProfile} />
      <UserMenuDivider /> */}
      <UserMenuItem as="button" onClick={logout}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Disconnect')}
          <LogoutIcon />
        </Flex>
      </UserMenuItem>
    </UIKitUserMenu>
  )
}

export default UserMenu
