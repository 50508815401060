import React, { useCallback, useMemo, useState } from 'react'
import {
  Text,
  Input,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Button,
  Flex,
} from '@pancakeswap/uikit'
import { AddressZero } from '@ethersproject/constants'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { rot13 } from 'utils/encode'
import { BASE_URL } from 'config'
import { ModalActions } from 'components/Modal'
import { useUserReferrer } from 'state/user/hooks'

const StyleInput = styled(Input)`
  margin-top: 10px;
`

interface RandomReferralModalProps extends InjectedModalProps {
  referrer?: string
}

const RandomReferralModal: React.FC<RandomReferralModalProps> = ({ referrer, onDismiss }) => {
  const { t } = useTranslation()

  const referralLink = useMemo(() => {
    if (referrer && referrer.toLowerCase() !== AddressZero) {
        return `${BASE_URL}/?ref=${rot13(referrer)}`
    }
    return undefined
  }, [referrer])

  const [, setUserReferrer] = useUserReferrer()

  const handleUseThis = useCallback(() => {

    if (referrer) {
      setUserReferrer(referrer)
    }
    onDismiss()

  }, [setUserReferrer, referrer, onDismiss])

  return (
    <ModalContainer title={t('Refferal Program')} minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Refferal Program')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="24px" maxWidth="400px" width="100%">
        <Flex flexDirection="column">
          <Text mb="8px">
            {t('You are joining us without any referral links, we recommend to use the following referral link to expand your referral network!')}
          </Text>
          <StyleInput type="text" scale="md" value={referralLink} readOnly/>
        </Flex>
        <ModalActions>
          <Button scale="md" variant="secondary" onClick={handleUseThis} width="100%">
            {t('Use This Link')}
          </Button>
        </ModalActions>
      </ModalBody>
    </ModalContainer>
  )
}

export default RandomReferralModal
