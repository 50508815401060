import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getLpConverterAddress = () => {
  return getAddress(addresses.lpConverter)
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}

export const getOldMasterChefAddress = () => {
  return getAddress(addresses.masterChefOld)
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getSpynVaultAddress = () => {
  return getAddress(addresses.spynVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialSpynVaultAddress = () => {
  return getAddress(addresses.bunnySpecialSpynVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
}
export const getReferralAddress = () => {
  return getAddress(addresses.referral)
}
export const getSpynNFTFactoryAddress = () => {
  return getAddress(addresses.spynNftFactory)
}
export const getSpynNFT2FactoryAddress = () => {
  return getAddress(addresses.spynNft2Factory)
}
export const getSpyNFTFactoryAddress = () => {
  return getAddress(addresses.spyNftFactory)
}
export const getNFTMintroxyAddress = () => {
  return getAddress(addresses.spynNftMintProxy)
}
export const getNFT2MintroxyAddress = () => {
  return getAddress(addresses.spynNft2MintProxy)
}
export const getSpynNFTRewardAddress = () => {
  return getAddress(addresses.spynNFTReward)
}
export const getSpynNFT2RewardAddress = () => {
  return getAddress(addresses.spynNFT2Reward)
}
export const getSpyNFTRewardAddress = () => {
  return getAddress(addresses.spyNFTReward)
}
export const getNFTMarketplaceAddress = () => {
  return getAddress(addresses.nftMarketplace)
}
export const getGovernanceAddress = () => {
  return getAddress(addresses.governance)
}

export const getAdminAddress = () => {
  return getAddress(addresses.admin)
}

export const getAdminOldAddress = () => {
  return getAddress(addresses.adminOld)
}

export const getNFTSignatureFactoryAddress = () => {
    return getAddress(addresses.signatureFactory)
}
export const getNFTSignatureMintProxyAddress = () => {
    return getAddress(addresses.signatureMintProxy)
}
export const getNFTSignatureRewardAddress = () => {
    return getAddress(addresses.signatureReward)
}
export const getNFTSignatureInsuranceAddress = () => {
    return getAddress(addresses.signatureInsurance)
}